import axios from 'axios';
import { getToken, logout } from '../../app/auth/service';

axios.interceptors.request.use(async config => {
  config.baseURL = process.env.REACT_APP_API_URL;
  
  if (!config.headers.Authorization) {
    const token = await getToken();
    axios.defaults.headers.common.Authorization = token;
    config.headers.Authorization = token;
  }

  return config;
}, null);

axios.interceptors.response.use(null, error => {
  const { config, request } = error;

  if (request.status === 401) {
    logout().then();
    return Promise.reject(error);
  }

  config.__isRetry = config.__isRetry || false;
  if (config.__isRetry) {
    return Promise.reject(error);
  }

  config.__isRetry = true;
  const backoff = new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, 1);
  });

  return backoff.then(() => axios(config));
});
