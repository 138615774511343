/**
 * EventTableRow
 * @brief  Event Table Header
 * @author [Jeremy Aftem]
 * @notes
 */

import React, { useState, useEffect } from 'react';
import {
  TableRow,
  TableCol,
  EventWrapper,
} from './styles';
import {
  ALERT_TYPE,
  EVENT_TYPE,
  TEXT_TYPE,
  JSX_TYPE,
} from './constants';
import {
  EVENT_WRAPPER_CSS,
  BED_STATE_MAP,
} from './constants';

const EventTableRow = ({ columns, row }) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/
  const [eventCSS, setEventCSS] = useState(null);

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/
  useEffect(() => {
    let _event;
    columns.forEach((col, idx) => {
      if (col.type === ALERT_TYPE) {
        _event = row[idx].text;
        _event = _event.toLowerCase();
        setEventCSS(EVENT_WRAPPER_CSS[_event].eventCSS);
      } else if (col.type === EVENT_TYPE) {
        _event = row[idx].text.toUpperCase();
        _event = BED_STATE_MAP[_event];
        setEventCSS(EVENT_WRAPPER_CSS[_event.id.toLowerCase()].eventCSS);
      }
    });
  }, [columns, row]);

  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  const renderColumn = (idx) => {
    if (columns[idx].type === ALERT_TYPE || columns[idx].type === EVENT_TYPE) {
      return (
        <TableCol row
          key={idx}
          width={columns[idx].width}
          flexgrow={columns[idx].flexGrow}
        >
          <EventWrapper style={eventCSS}>
            { columns[idx].type === EVENT_TYPE && BED_STATE_MAP[row[idx].text].id }
            { columns[idx].type === ALERT_TYPE && row[idx].text.toUpperCase() }
          </EventWrapper>
        </TableCol>
      );
    } else if (columns[idx].type === TEXT_TYPE) {
      return (
        <TableCol row
          key={idx}
          width={columns[idx].width}
          flexgrow={columns[idx].flexGrow}
          style={columns[idx].style}
        >
          {row[idx].text}
        </TableCol>
      );
    } else if (columns[idx].type === JSX_TYPE) {
      return (
        <TableCol row
          key={idx}
          width={columns[idx].width}
          flexgrow={columns[idx].flexGrow}
          style={columns[idx].style}
        >
          {row[idx].render()}
        </TableCol>
      )
    }
  };

  return (
    <TableRow row>
      {
        row.map((col, idx) => {
          return renderColumn(idx);
        })
      }
    </TableRow>
  );
};

export default EventTableRow;