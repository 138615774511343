/* pub sub model to subscribe to any changes in router history object */
import useForceUpdate from 'use-force-update';
import { useContext, useEffect } from 'react';
import { __RouterContext } from 'react-router-dom';

const useReactRouter = () => {
  const forceUpdate = useForceUpdate();
  const routerContext = useContext(__RouterContext);

  useEffect(() => {
    const unsubscribe = routerContext.history.listen(forceUpdate);
    return () => {
      unsubscribe();
    };
  });

  return routerContext;
};

export default useReactRouter;
