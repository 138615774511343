/**
 * MonitorPopups
 * @brief  Manages all monitor popups
 * @author [Jeremy Aftem]
 */

import React, { useEffect, useState, useCallback } from 'react';
import Alerts from '../Alerts';
import BedPopup from '../Beds/Bed/BedPopup';

const POPUP_STATE = {
  closed: 'closed',
  alertsOpen: 'alerts_open',
  bedOpen: 'bed_open',
};

const MonitorPopups = ({
  user,
  alerts,
  bedPopupData,
  onAlertsClicked,
  onBedPopupClicked,
  onClosePopups,
 }) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/
  const [popupState, setPopupState] = useState(POPUP_STATE.closed);
  const [clickedBed, setClickedBed] = useState(null);

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/

  const getLatestByReceivedAt = useCallback((_alerts)=>{
    let latest = _alerts[0];
    let latestDate = new Date(_alerts[0].receivedAt);
    _alerts.forEach((_alert) => {
      let currentDate = new Date(_alert.receivedAt);
      if (currentDate > latestDate) {
        latest = _alert;
        latestDate = currentDate;
      }
    });
    return latest;
  }, []);

  useEffect(() => {
    // Bed clicked
    if (onBedPopupClicked && bedPopupData) {
      setClickedBed(bedPopupData);
      setPopupState(POPUP_STATE.bedOpen);
    }
  }, [bedPopupData, onBedPopupClicked]);

  useEffect(() => {
    // Alerts clicked
    if (onAlertsClicked) {
      setPopupState(POPUP_STATE.alertsOpen);
    } 
  }, [onAlertsClicked]);

  useEffect(() => {
    if (!alerts.length) { return; }
    if (!clickedBed && hasAlerts(alerts)) {
      setPopupState(POPUP_STATE.alertsOpen);
    }
    const latestAlert = getLatestByReceivedAt(alerts);
    if (
      popupState === POPUP_STATE.bedOpen &&
      clickedBed.id !== latestAlert.roomDeviceId
    ) {
      setPopupState(POPUP_STATE.alertsOpen);
    } else {
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts, getLatestByReceivedAt,])
  

  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/

  const hasAlerts = (_alerts) => {
    let ret = false;
    _alerts.forEach((_alert) => {
      if (_alert.alertState === 'ACTIVE') {
        ret = true;
      }
    });
    return ret;
  };

  const onClose = () => {
    setPopupState(POPUP_STATE.closed);
    setClickedBed(null);
    onClosePopups();
  };

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  const render = () => {
      return (
        <>
          <Alerts
            user={user}
            alerts={alerts}
            isOpen={popupState === POPUP_STATE.alertsOpen}
            onClose={onClose}
          />
          {popupState === POPUP_STATE.bedOpen && (
            <BedPopup
              user={user}
              alerts={alerts}
              bedData={clickedBed}
              isOpen={popupState === POPUP_STATE.bedOpen}
              onClose={onClose}
            />
          )}
        </>
      );
  }
  return render();
};

export default MonitorPopups;