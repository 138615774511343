/**
 * Overivew
 * @brief  Overview
 * @author [Jeremy Aftem]
 */

import React, { useState, useEffect } from 'react';
import {
  OverviewItem,
  OverviewItemHeader,
  OverviewItemBody,
  OverviewSpacer,
  OverviewBody,
  OverviewAlertWrapper,
  OverviewNormalWrapper,
} from './styles';

const Overview = ({ alerts, roomDeviceState, onClickAlerts }) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/
  const [pendingAlerts, setPendingAlerts] = useState([]);

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/
  useEffect(() => {
    if (hasPendingAlerts(alerts)) {
      setPendingAlerts(alerts);
    } else {
      setPendingAlerts([]);
    }
  }, [alerts]);

  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/

  const hasPendingAlerts = (_alerts) => {
    let ret = false;  
    _alerts.forEach((_alert) => {
      if (_alert.alertState === 'ACTIVE') {
        ret = true;
      }
    });
    return ret;
  }

  const renderPendingAlerts = () => {
    if (pendingAlerts.length) {
      return (
        <OverviewAlertWrapper onClick={()=>{onClickAlerts()}}>
          {pendingAlerts.length > 1 ? (
            `${pendingAlerts.length} pending alerts`
          ) : (
            `${pendingAlerts.length} pending alert`
          )}
        </OverviewAlertWrapper>
      );
    } else {
      return (
        <OverviewNormalWrapper>
          No pending alerts 
        </OverviewNormalWrapper>
      );
    }
  };

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  return (
    <OverviewBody row>
      <OverviewItem>
        <OverviewItemHeader>PENDING ALERTS</OverviewItemHeader>
        <OverviewItemBody>
          {renderPendingAlerts()}
        </OverviewItemBody>
      </OverviewItem>
      <OverviewSpacer />
      <OverviewItem>
        <OverviewItemHeader>DEVICES ONLINE</OverviewItemHeader>
        <OverviewItemBody>
          X/Y devices online. <br />
        </OverviewItemBody>
      </OverviewItem>
      <OverviewSpacer />
      <OverviewItem>
        <OverviewItemHeader>RESPONSE</OverviewItemHeader>
        <OverviewItemBody>
          8.4s average response time
        </OverviewItemBody>
      </OverviewItem>
    </OverviewBody>
  );
};

export default Overview;
