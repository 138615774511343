import styled, { } from 'styled-components';
import { LayoutBox } from '../../../../../../../components/layout/styles';


export const TableHeader = styled(LayoutBox)`
  background-color: #FAFAFA;
  width: ${props => props.width};
`;

export const TableHeaderCol = styled(LayoutBox)`
  color: #545454;
  font-size: 12px;
  padding: 10px;
  flex-grow: ${props => props.flexgrow};
  width: ${props => props.width}
`;
