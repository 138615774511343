import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { usePusher } from '../../usePusher';

/**
 * @hook handles pusher interactions
 * @description state manager for pusher data
 *
 * @example
 *  // we configure our pusher channel with the channel 'sampleChannel', and the event 'sampleEvent'
 *  // our useEffect hook will execute every time our pusher channel receives a message
 *
 *  const [messages] = useChannel({ ch: 'sampleChannel', chEvent: 'sampleEvent' });
 *
 *  useEffect(() => {
 *    if (messages.length) // do something
 *  }, [messages]);
 *
 */

// Global channels
let channels = {};

const useChannel = config => {
  // Use the pusher hook to get the pusher instance from context
  const pusher = usePusher();
  const [messages, setMessages] = useState([]);

  const stringifiedConfig = JSON.stringify(config);

  // Set up the side effect, each time a message comes in
  // on the child-channel with an event type 'chEvent',
  // add the payload to the messages array
  useEffect(() => {
    function eventCallback(data) {
      const newMessages = [...messages, data];
      setMessages(newMessages);
    }
    if (config) {
      const { ch, chEvent } = config;
      let channel = null;
      if (channels[ch.toString()]) {
        channel = channels[ch.toString()];
      } else {
        channel = pusher.subscribe(ch.toString());
        channels[ch.toString()] = channel;
      }
      channel.bind(chEvent, eventCallback);

      return () => {
        channel.unbind(chEvent, eventCallback);
      };
    }
    return () => {};
  }, [config, messages, pusher, stringifiedConfig]);

  const clear = useCallback(() => {
    setMessages([]);
  }, []);

  const messagesMemo = useMemo(() => messages, [messages]);

  return [messagesMemo, clear];
};

useChannel.propTypes = {
  config: PropTypes.shape({
    ch: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chEvent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default useChannel;