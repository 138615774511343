import styled, { } from 'styled-components';
import { LayoutBox } from '../../../../../../../components/layout/styles';

export const EventTableLayout = styled(LayoutBox)`
  width: 100%;
`;


export const TableRow = styled(LayoutBox)`
  margin-top: 8px;
  margin-bottom: 8px;
  color: #545454;
  font-weight: 500;
  :hover {
    background-color: #FAFAFA;
  }
`;

export const TableCol = styled(LayoutBox)`
  font-size: 14.5px;
  padding: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  align-items: center;
  color: #545454;
  width: ${props => props.width};
  flex-grow: ${props => props.flexgrow};
`;

export const EventWrapper = styled.div`
  margin-left: 0px;
  font-size: 12.5px;
  font-weight: 600;
  padding: 8px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 3px; 
`;
