/**
 * AttendButton
 * @brief  Attend button
 * @author [Jeremy Aftem]
 */

import React, { useState, useEffect } from 'react';
import { AttendWrapper } from './styles';
import useVitalacyAPI from '../../../../../../hooks/useVitalacyAPI';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EVENT_WRAPPER_CSS } from '../../../layout/EventTable/EventTableRow/constants';

const AttendButton = ({ user, data, updateAsAttended }) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/
  // eslint-disable-next-line no-unused-vars
  const [attendCall, doAttendCall]  = useVitalacyAPI(
    {method: 'POST'},
    {manual: true},
  );
  // eslint-disable-next-line no-unused-vars
  const [undoCall, doUndoCall] = useVitalacyAPI(
    {method: 'POST'},
    {manual: true},
  );
  const [isLoading, setLoading] = useState(false);

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/

  useEffect(() => {
    setLoading(false);
  }, [data])

  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/

  const onClickAttend = () => {
    setLoading(true);
    doAttendCall({
      url: `/bedalert/respond`,
      data: {
        id: data.id,
        message: 'User set attended status from web.',
        groupId: user.groupId,
        responderId: user.id,
        reporterId: user.id,
        alertState: 'ATTENDED',
      }
    });
    updateAsAttended(data.id, true);
  };

  const onClickUndo = () => {
    setLoading(true);
    doUndoCall({
      url: `/bedalert/respond/undo`,
      data: {
        id: data.id,
        attendedBedEventId: data.attendedBedEventId,
        groupId: user.groupId,
      },
    });
  }

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  const render = () => {
    return (
      <>
        { data.alertState.toUpperCase() !== 'ATTENDED' && !isLoading && 
          <AttendWrapper
            style={EVENT_WRAPPER_CSS['attended'].eventCSS}
            onClick={()=>{onClickAttend()}}
          >
            ATTEND
          </AttendWrapper>
        }
        { isLoading && 
          <FontAwesomeIcon icon={faCircleNotch} spin /> 
        }
        { data.alertState.toUpperCase() === 'ATTENDED' && !isLoading &&
          <AttendWrapper
            style={EVENT_WRAPPER_CSS['alert'].eventCSS}
            onClick={()=>{onClickUndo()}}
          >
            Undo
          </AttendWrapper>
        }
      </>
    )
  }

  return render();
};

export default AttendButton;