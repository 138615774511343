/**
 * AlertsTable
 * @brief  AlertsTable
 * @author [Jeremy Aftem]
 */

import React, { useState, useEffect, useCallback } from 'react';
import {
  ALERT_TYPE,
  TEXT_TYPE,
  JSX_TYPE,
} from '../../layout/EventTable/EventTableRow/constants'
import EventTable from '../../layout/EventTable';
import AttendButton from './AttendButton'

const AlertsTable = ({ user, alerts, isOpen, attended, setAttended }) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/
  const [table, setTable] = useState({
    columns: [
      {
        name: 'EVENT',
        type: ALERT_TYPE,
        flexGrow: 0,
        width: '115px',
        style: null,
      },
      {
        name: 'DETAILS',
        type: TEXT_TYPE,
        flexGrow: 1,
        width: null,
        style: null,
      },
      {
        name: 'ATTEND PATIENT',
        type: JSX_TYPE,
        flexGrow: 0,
        width: '110px',
        style: {
          justifyContent: 'center',
        }
      },
    ],
    rows: []
  }) 

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/


  const updateAsAttended = useCallback((roomDeviceId, status) => {
    let toUpdate = [...attended];
    if (status) {
      toUpdate.push(roomDeviceId); // Add
    } else {
      toUpdate = toUpdate.filter((el) => el !== roomDeviceId); // Remove
    }
    setAttended(toUpdate);
  }, [attended, setAttended]);

  useEffect(() => {
    if (!isOpen) {
      setAttended([]);
    }
  }, [isOpen, setAttended])

  useEffect(() => {
    let rows = [];
    if (!alerts) { return; }
    alerts.forEach((item) => {
      let cols = [
        {text: item.alertType},
        {text: item.message},
        {render: () => {
          return (
            <AttendButton
              key={item.id}
              user={user}
              data={item}
              updateAsAttended={updateAsAttended}
            />
          )
        }}
      ];
      rows.push(cols);
      setTable({
        columns: table.columns,
        rows, 
      })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts, setTable, user]);

  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/


  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  const render = () => {
    if (!table.rows.length) {
      return (<div/>)
    } else {
      return (
        <EventTable data={table} />
      );
    }
  }

  return render();
};

export default AlertsTable;