/**
 * BedsView
 * 
 * Displays bed information in a grid. The grid consists of squares or
 * rectangles that are nearly squares. The grid conforms to the parent
 * component's width and height, so fitting perfect squares is not actually
 * always possible (in fact, mostly not possible).
 * 
 * Instead, the grid calculates the maximum dimensions of each square that would
 * fit into this container rounding down. With these square dimensions, the grid
 * calculates the maximum squares per row. The grid enforces this number of
 * squares on each row without enforcing the width of each square allowing the
 * CSS and flexbox to calculate a width for each 'square' to perfectly fit the
 * width of the parent container. The height of each row is enforced by the
 * square dimensions.
 * 
 * This means grid 'squares' will be stretched horizontally to fit the container
 * instead of vertically.
 * 
 * @brief  BedsView view
 * @author [Jeremy Aftem]
 */

import React, { useEffect, useState, useCallback, useRef  } from 'react';
import Bed from './Bed';
import useDimensions from 'react-cool-dimensions';
import { GridBox, GridRow } from './styles';

const LOAD_TIMER_ms = 50;

const BedsView = ({ user, roomDevicesData, updateRoomDevices, onClickBed }) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/
  const [boxDim, setBoxDim] = useState({});
  const [grid, setGrid] = useState(null);
  const [loading, setLoading] = useState(false);
  const updatedRoomDevicesData = useRef(null);
  let loadingTimer = useRef(null);

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/
  /**
   * generateGrid
   * Generate a grid (or matrix) of beds to be displayed to the user.
   * @param beds List of bed data
   * @param squaresPerRow to enforce number of elements per row
   */
  const generateGrid = useCallback((beds, squaresPerRow) => {
    if (!squaresPerRow) return;
    let grid = [];
    let gridIdx = -1;
    beds.forEach((el, idx) => {
      if (idx % squaresPerRow === 0) {
        let newRow = [];
        newRow.push(el);
        grid.push(newRow);
        gridIdx = gridIdx + 1;
      } else {
        grid[gridIdx].push(el);
      }
    });
    return grid;
  }, [])

  /**
   * executeLoading
   * Only re-render grid LOAD_TIMER_ms miliseconds after state change
   */
  const executeLoading = useCallback(() => {
    setLoading(true);
    if (loadingTimer.current) {
      clearTimeout(loadingTimer.current);
    }
    loadingTimer.current = setTimeout(()=>{
      setLoading(false);
      // At end of load, update roomDevices with changes we have found
      // from live updates.
      if (updatedRoomDevicesData.current) {
        updateRoomDevices(updatedRoomDevicesData.current);
      }
    }, LOAD_TIMER_ms)
  }, [loadingTimer, updatedRoomDevicesData, updateRoomDevices]);

  /**
   * Observe dimension changes to grid container
   * This will trigger a new grid
   */
  const { observe } = useDimensions({
    onResize: ({ width, height }) => {
      setBoxDim({
        width: width,
        height: height
      });
    },
  });

  /**
   * Grid update hook
   * Generate a new grid when state variables update
   * This will trigger a new grid
   */
  useEffect(() => {
    if (!roomDevicesData.length) {
      return;
    }
    updatedRoomDevicesData.current = roomDevicesData;
    const numBeds = roomDevicesData.length;
    const squareDim = Math.sqrt(
      (boxDim.width * boxDim.height) / numBeds
    );
    let squaresPerRow = Math.floor(boxDim.width / squareDim);
    const _newGrid = generateGrid(
      roomDevicesData,
      squaresPerRow
    );
    if (_newGrid) {
      let newGrid = {};
      newGrid.grid = _newGrid;
      newGrid.squaresPerRow = squaresPerRow;
      newGrid.gridBoxDim = boxDim;
      setGrid(newGrid);
      executeLoading();
    } else {
      setGrid(null);
    }
  }, [roomDevicesData, boxDim, generateGrid, executeLoading]);

  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/

  /**
   * On new live updates, update a reference variable updatedRoomDevicesData
   * that is a copy of roomDevices with the updated event. Later if the user
   * resizes the screen, we update roomDevices with updatedRoomDevicesData after
   * loading is finished.
   * This allows each Bed component to re-render itself if it has a live update,
   * but not re-render the entire grid. When the entire grid must be re-rendered
   * we ensure the live updates found since init are applied to the initial data
   * retrieved from the server.
   */
  const updateLiveBed = (alert, bedEvent) => {
    let toUpdate = roomDevicesData.map((el) => {
      // Update bed event
      if (el.hasOwnProperty('bedEvent') && bedEvent != null) {
        if (el.bedEvent.roomDeviceId === bedEvent.roomDeviceId) {
          el.bedEvent = bedEvent;
        }
      }
      // Update alert
      if (alert != null) {
        if (el.id === alert.roomDeviceId) {
          el.alert = alert;
        }
      }
      return el; 
    });
    updatedRoomDevicesData.current = toUpdate;
  }

  const renderGrid = () => {
    // Do not render if grid hasn't been generated or if loading
    if (!grid || loading) return (<div />)
    return (
      grid.grid.map((row, rowIdx) => {
        return (
          <GridRow
            column colgap8 rowgap8
            key={rowIdx}
            width={
              rowIdx !== (grid.grid.length - 1) ?
              '100%' :
              `calc(${row.length/grid.squaresPerRow * 100}% - 2px)` // Last row
            }
            height={1/grid.squaresPerRow * grid.gridBoxDim.width + 'px'}>
            { row.map((bed) => {
              const bedKey = bed.id;
              return (
                <Bed
                  user={user}
                  size={(1/grid.squaresPerRow) * grid.gridBoxDim.width}
                  key={bedKey}
                  bedData={bed}
                  updateLiveBed={updateLiveBed}
                  onClickBed={onClickBed}
                />
              );
            })}
          </GridRow>
        )
    }))
  }

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  return (
    <GridBox column ref={observe}>
      { renderGrid() }
    </GridBox>
  );
};

export default BedsView;