import React from 'react';

const PusherContext = React.createContext();

// eslint-disable-next-line react/prop-types
function PusherProvider({ pusher, children }) {
  return (
    <PusherContext.Provider value={pusher}>{children}</PusherContext.Provider>
  );
}

function usePusher() {
  const context = React.useContext(PusherContext);
  if (context === undefined) {
    throw new Error(`usePusher must be used within a PusherProvider`);
  }

  return context;
}

export { PusherProvider, usePusher };
