import styled, { css } from 'styled-components';

/* *********************************************************************************************************************
 *                                                  REUSABLE STYLES                                                    *
 ******************************************************************************************************************** */
export const LayoutBox = styled.div`
  height: ${props => props.height && props.height};
  width: ${props => (props.fullWidth && '100%') || props.width};
  min-width: ${props => props.minWidth};
  flex: ${props =>
    (props.flexesTo1 && 1) ||
    (props.flexesTo2 && 2) ||
    (props.flexesTo3 && 3) ||
    (props.flexesTo6 && 6) ||
    (props.flexesTo7 && 7)};
  display: ${props => (props.column || props.row) && 'flex'};
  flex-direction: ${props => props.column && 'column'};
  justify-content: ${props =>
    (props.flexEnd && 'flex-end') ||
    (props.jcCenter && 'center') ||
    (props.spaceBtx && 'space-between')};
  overflow: ${props =>
    (props.scrolls && 'auto') || (props.hideOverflow && 'hidden')};
  padding-top: ${props =>
    (props.pt8 && '8px') ||
    (props.pt16 && '16px') ||
    (props.pt20 && '20px') ||
    (props.pt70 && '70px')};
  padding-bottom: ${props =>
    (props.pb8 && '8px') ||
    (props.pb10 && '10px') ||
    (props.pb16 && '16px') ||
    (props.pb20 && '20px') ||
    (props.pb40 && '40px')};
  padding-right: ${props =>
    (props.pr4 && '4px') ||
    (props.pr8 && '8px') ||
    (props.pr10 && '10px') ||
    (props.pr20 && '20px') ||
    (props.pr24 && '24px') ||
    (props.pr30 && '30px') ||
    (props.pr40 && '40px') ||
    (props.pr60 && '60px')};
  padding-left: ${props =>
    (props.pl4 && '4px') ||
    (props.pl8 && '8px') ||
    (props.pl10 && '10px') ||
    (props.pl20 && '20px') ||
    (props.pl30 && '30px') ||
    (props.pl40 && '40px') ||
    (props.pl60 && '60px')};
  padding: ${props =>
    (props.p2x4 && '2px 4px') ||
    (props.p8 && '8px') ||
    (props.p16x18 && '16px 18px') ||
    (props.p16x20 && '16px 20px')};
  margin-top: ${props =>
    (props.mt4 && '4px') ||
    (props.mt8 && '8px') ||
    (props.mt12 && '12px') ||
    (props.mt16 && '16px') ||
    (props.mt24 && '24px') ||
    (props.mt32 && '32px') ||
    (props.ba && 'auto')};
  margin-right: ${props =>
    (props.mr4 && '4px') ||
    (props.mr8 && '8px') ||
    (props.mr12 && '12px') ||
    (props.mr16 && '16px') ||
    (props.mr20 && '20px') ||
    (props.mr24 && '24px') ||
    (props.mr30 && '30px')};
  margin-left: ${props =>
    (props.ml4 && '4px') ||
    (props.ml8 && '8px') ||
    (props.ra && 'auto') ||
    (props.mla && 'auto') ||
    (props.ml12 && '12px') ||
    (props.ml16 && '16px') ||
    (props.ml20 && '20px') ||
    (props.ml24 && '24px') ||
    (props.ml30 && '30px')};
  margin-bottom: ${props =>
    (props.mb8 && '8px') ||
    (props.mb12 && '12px') ||
    (props.mb16 && '16px') ||
    (props.mb32 && '32px')};
  position: ${props =>
    (props.relative && 'relative') ||
    (props.absolute && 'absolute') ||
    (props.fixed && 'fixed')};
  top: ${props => props.t0 && 0};
  right: ${props => props.r0 && 0};
  border: ${props => props.wb1 && '1px solid #e6e9eb'};
  border-radius: ${props =>
    (props.br4 && '4px') ||
    (props.br6 && '6px') ||
    (props.br8 && '8px') ||
    (props.br12 && '12px') ||
    (props.br25 && '25px') ||
    (props.wb1 && '4px') ||
    (props.icon && '50%')};
  box-shadow: ${props => props.medShadow && '0 2px 6px 0 hsla(0, 0%, 0%, 0.2)'};
  align-items: ${props =>
    (props.center && 'center') || (props.baseline && 'baseline')};
  text-align: ${props =>
    (props.taCenter && 'center') || (props.taLeft && 'left')};
  background-color: ${props =>
    (props.bgWhite && 'rgb(255, 255, 255)') ||
    (props.bgGray && 'rgb(250, 250, 250)') ||
    (props.danger && 'rgb(253, 234, 241)') ||
    (props.safe && 'rgb(231, 249, 232)') ||
    (props.icon && 'rgb(246, 245, 250)')};
  z-index: ${props =>
    (props.z3 && 3) || (props.z30 && 30) || (props.z40 && 40)};

  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 480px) {
    display: ${props => props.md480inlineBlock && 'inline-block'};
    flex-direction: ${props => props.md480Col && 'column'};
    position: ${props => props.md480fixed && 'fixed'};
    padding-top: ${props =>
      (props.md480pt50 && '50px') ||
      (props.md480pt64 && '64px') ||
      (props.md480pt75 && '75px') ||
      (props.md480pt115 && '115px')};
    padding-right: ${props => props.md480pr24 && '24px'};
    padding-left: ${props => props.md480pl24 && '24px'};
    margin-top: ${props => props.md480mt56 && '56px'};
  }
  
  column-gap: ${props =>
    (props.colgap8 && '8px')   ||
    (props.colgap16 && '16px') ||
    (props.colgap20 && '20px') || 
    (props.colgap24 && '24px')}; 

  row-gap: ${props =>
    (props.rowgap8 && '8px')   ||
    (props.rowgap16 && '16px') ||
    (props.rowgap20 && '20px') || 
    (props.rowgap24 && '24px')};
`;

export const StickyCard = styled.div`
  flex: ${props =>
    (props.flex1 && 1) ||
    (props.flex2 && 2) ||
    (props.flex3 && 3) ||
    (props.flex4 && 4) ||
    (props.flex5 && 5) ||
    (props.flex6 && 6) ||
    (props.flex7 && 7)};
  flex-direction: ${props => props.col && 'column'};
  margin-right: ${props => props.mr12 && '12px'};
  margin-left: ${props => props.ml12 && '12px'};
  padding: ${props => props.p16x18 && '16px 18px'};
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  border-radius: 8px;
  border: 1px solid #e6e9eb;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: ${props => props.mb32 && '32px'};
`;

export const Card = styled.div`
  display: flex;
  position: relative;
  flex: ${props => props.flexesTo1 && '1 1 auto'};
  // overflow: hidden;
  border-radius: 8px;
  background-color: ${props => props.light && '#fff'};
  box-shadow: ${props =>
    props.border
      ? 'none'
      : '0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12)'};
  border: ${props => props.border && '1px solid #e6e9eb'};
  padding: ${props => (props.p16 && '16px') || (props.p20 && '20px')};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 4px 0;
  margin-bottom: ${props => (props.mb20 && '20px') || (props.mb24 && '24px')};
  > span {
    display: flex;
    margin: 0 4px;
  }
`;

export const Heading = styled.span`
  align-self: ${props => props.flexStart && 'flex-start'};
  color: #222;
  font-size: ${props =>
    (props.fs14 && '14px') ||
    (props.fs16 && '16px') ||
    (props.fs18 && '18px') ||
    '22px'};
  letter-spacing: 0.2px;
  font-weight: 600;
  padding-top: ${props => props.pt4 && '4px'};
  padding-bottom: ${props => (props.pb4 && '4px') || (props.pb8 && '8px')};
  padding-left: ${props => (props.pl4 && '4px') || (props.pl12 && '12px')};
  padding-right: ${props => props.pr4 && '4px'};
  margin-top: ${props => props.mt16 && '16px'};
  margin-right: ${props => props.mr4 && '4px'};
  margin-bottom: ${props => props.mb16 && '16px'};
  margin-left: ${props => props.ml4 && '4px'};
  ${props =>
    props.secondary &&
    css`
      font-size: 18px;
      font-weight: 500;
    `};
  @media (max-width: 480px) {
    padding-bottom: ${props => props.mobilePb32 && '32px'};
  }
`;

export const Header = styled.span`
  color: rgb(94, 108, 132);
  font-size: ${props => (props.f13 && '13px') || '12px'};
  font-weight: bold;
  letter-spacing: 0.05px;
  margin-bottom: ${props => props.mb8 && '8px'};
`;

export const Text = styled.span`
  font-weight: ${props =>
    (props.fw400 && 400) ||
    (props.fw500 && 500) ||
    (props.fw600 && 600) ||
    (props.fw900 && 900)};
  padding-top: ${props => (props.pt2 && '2px') || (props.pt4 && '4px')};
  padding-bottom: ${props =>
    (props.pb2 && '2px') || (props.pb4 && '4px') || (props.pb8 && '8px')};
  padding-left: ${props => props.pl2 && '2px'};
  padding-right: ${props => props.pr2 && '2px'};
  padding: ${props => props.p0x2 && '0px 2px'};
  margin-right: ${props => props.mr8 && '8px'};
  margin-left: ${props => props.ml8 && '8px'};
  font-size: ${props =>
    (props.fs10 && '10px') ||
    (props.fs11 && '11px') ||
    (props.fs12 && '12px') ||
    (props.fs13 && '13px') ||
    (props.fs14 && '14px') ||
    (props.fs15 && '15px') ||
    (props.fs16 && '16px') ||
    (props.fs18 && '18px') ||
    (props.fs20 && '20px') ||
    (props.fs24 && '24px') ||
    (props.fs26 && '26px') ||
    (props.fs30 && '30px')};
  line-height: ${props =>
    (props.lh11 && '11px') ||
    (props.lh12 && '12px') ||
    (props.lh13 && '13px') ||
    (props.lh14 && '14px')};
  color: ${props =>
    (props.white && '#fff') ||
    (props.black && '#222') ||
    (props.blue && 'rgba(0, 138, 255, 1)') ||
    (props.primary && 'rgba(91,88,172,1)') ||
    (props.primaryDark && 'rgba(88, 92, 140, 1)') ||
    (props.safe && 'rgb(94, 188, 133)') ||
    (props.danger && '#ff7590') ||
    (props.bold && 'hsl(207,12%,40%)') ||
    (props.med && 'hsl(207,6%,50%)') ||
    (props.light && 'hsl(207,6%,60%)') ||
    (props.lightest && 'hsla(0, 0%, 77%, 1)')};
  background: ${props => props.bgPrimary && 'rgba(240,240,254,0.8)'};
  align-self: ${props => props.center && 'center'};
  text-decoration: ${props => props.lineThrough && 'line-through'};
  border-bottom: ${props => props.underlined && '1px solid #e6e9eb'};
  text-transform: ${props => props.cap && 'capitalize'};
  border-radius: 4px;
`;

/*
export const NavLink = styled(NavigationLink)`
  font-size: 16px;
  color: rgba(134, 71, 254, 1);
  text-decoration: none;
  cursor: pointer;
`;
*/

export const IconRoot = styled.span`
  display: flex;
  align-items: center;
  color: ${props =>
    (props.danger && '#f75990') || (props.safe && 'rgb(94, 188, 133)')};
  padding: ${props => (props.p2 && '2px') || (props.p4 && '4px')};
  padding-left: ${props => props.pl4 && '4px'};
`;

export const SearchInput = styled.input.attrs({ type: 'search' })`
  min-height: ${props => (props.big && '38px') || '35px'};
  width: ${props => (props.big && '275px') || '200px'};
  padding: ${props => (props.big && '0 10px 0 44px') || '0 10px 0 38px'};
  color: hsl(207, 6%, 50%);
  background: ${props => (props.flat ? '#f8f9fa' : 'rgb(250, 251, 252)')};
  border: solid 1px #f1f1f1;
  border-radius: 4px;
  font-size: ${props => (props.small ? '12px' : '14px')};
  display: block;
  font-weight: 600;
  transition: background-color 200ms ease, outline 200ms ease, color 200ms ease,
    box-shadow 200ms ease;

  :hover {
    background: #fff;
    box-shadow: 0 0 0 4px #ded2fe;
    border: solid 1px rgba(135, 100, 255, 1);
  }
  :focus {
    background: #fff;
    box-shadow: 0 0 0 4px #ded2fe;
    border-color: rgba(135, 100, 255, 1);
    outline: none;
  }
  :valid {
  }
  /* this removes the autocompleted styling browsers add */
  -webkit-appearance: none;
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background-color: none !important;
  }
  :-internal-autofill-selected {
    background-color: none !important;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: hsl(207, 6%, 60%);
  }
  :-ms-input-placeholder {
    color: hsl(207, 6%, 60%);
  }
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  color: #8a8f92;
  padding: 0 0 4px 4px;
`;


/*
export const SearchIcon = styled(RiSearchLine)`
  color: rgba(91, 88, 172, 0.7);
  cursor: pointer;
  margin: auto;
  position: absolute;
  bottom: 50%;
  top: 50%;
  left: 5%;
`;
*/

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: ${props => (props.rounded ? '25px' : '4px')};
  width: ${props => (props.small ? '35px' : '80px')};
  background: ${props =>
    (props.default && '#f8f9fa') ||
    (props.danger && '#FCE8E8') ||
    (props.primary && '#8764fe')};
  color: ${props => props.primary && '#fff'};
  margin-left: 12px;
  cursor: pointer;
  padding-top: ${props => props.pt8 && '8px'};
  padding-bottom: ${props => props.pb8 && '8px'};
  font-size: ${props => props.fs14 && '14px'};

  transition: transform 0.1s ease-in;

  :hover {
    transform: scale(1.05);
  }
  :focus {
    outline: none;
  }
  :disabled {
    background: rgb(147, 142, 166);
    color: #fff;
    transform: none;
    cursor: unset;
  }
`;

export const TableStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: ${props => props.flexesTo1 && 1};

  table {
    table-layout: fixed;
    white-space: nowrap;
    border-spacing: 0;
    width: 100%;
    padding: 0;

    thead {
      display: ${props => props.noHeaders && 'none'};
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }

      :hover {
        button {
          visibility: visible;
        }
        span {
          visibility: visible;
        }
      }
    }
    th {
      margin: 0;
      padding: 4px 12px;
      color: rgb(94, 108, 132);
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.05px;
      white-space: nowrap;
      text-align: left;
      border-bottom: 2px solid hsl(210, 23%, 95%);

      div {
        height: 25px;
        display: flex;
        align-items: center;
      }

      :first-child {
        div {
          display: ${props => props.hideFirst && 'none'};
        }
      }
      :last-child {
        div {
          display: ${props => props.hideLast && 'none'};
        }
      }
    }
    td {
      margin: 0;
      padding: 5px;
      font-size: 14px;
      font-weight: 500;
      height: 40px;
    }
    tbody {
      color: #495057;

      tr {
        background: ${props => props.rowBg && '#f5f7fa'};
        td {
          border-bottom: none;
        }
        :hover {
          background: hsla(216, 33%, 97%, 0.2);

          td {
          }
        }
      }
    }
  }
  // pagination
  > div {
    display: flex;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    border-top: 1px solid hsl(210, 23%, 95%);

    span {
      font-size: 12px;
    }

    button {
      min-width: 35px;
      height: 35px;
      font-size: 12px;
      border: none;
      color: hsl(207, 6%, 50%);
      cursor: pointer;
      background: transparent;
      transition: background 0.15s ease-in-out;

      :disabled {
        cursor: default;
        background: transparent !important;
      }
      :hover {
        background: hsl(210, 9%, 96%);
      }
      :focus {
        outline: none;
      }
    }

    > div {
      line-height: 36px;
      color: #737376;
      font-size: 14px;
    }
  }
`;

export const FallbackText = styled.h3`
  position: absolute;
  left: 0;
  right: 0;
  top: 25%;
  margin: 18.72px auto;
  color: #737376;
  font-weight: 500;
  width: 250px;
`;

export const CheckInCode = styled.div`
  height: 20px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 3px;
  border-radius: 5px;
`;