import styled, { } from 'styled-components';
import { LayoutBox } from '../../../components/layout/styles';

export const LiveHeader = styled(LayoutBox)`
  padding-left: 30px;
  padding-right: 30px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
`;

export const LiveLogo = styled(LayoutBox)`
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
`;

export const LiveLogoText = styled(LayoutBox)`
  margin-left: 14px;
  margin-top: 4px;
  font-size: 22px;
  font-weight: 460;
  color: #1E1E1E;
`;

export const LiveMenu = styled(LayoutBox)`
  align-items: center;
`;

export const LiveMenuItem = styled(LayoutBox)`
  margin-left: 40px;
  font-weight: 500;
  font-size: 18px;
  color: #1E1E1E;
`;

export const LiveBody = styled(LayoutBox)`
  width: 100%;
  height: 100%;
`;

export const HelpBox = styled(LayoutBox)`
  text-align: center;
  font-size: 28px;
  line-height: 42px;
  align-items: center;
  justify-content: center;
  height: 70%;
`;