import styled, { } from 'styled-components';
import { LayoutBox } from '../../../../../components/layout/styles';


export const GridBox = styled(LayoutBox)`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
`;

export const GridRow = styled(LayoutBox)`
  overflow: hidden;
  flex-wrap: wrap;
  margin-top: 8px;
  width: ${props => props.width};
  height: ${props => props.height};
`;

export const OverviewItem = styled(LayoutBox)`
  margin-right: 200px;
`;

export const OverviewItemHeader = styled(LayoutBox)`
  font-size: 14px;
  color: #545454;
`;

export const OverviewItemBody = styled(LayoutBox)`
  margin-top: 20px;
  font-size: 20px;
  color: #1E1E1E;
`;