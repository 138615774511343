/**
 * EventTableHeader
 * @brief  Event Table Header
 * @author [Jeremy Aftem]
 * @notes
 */

import React, { } from 'react';
import {
  TableHeader,
  TableHeaderCol,
} from './styles';

const EventTableHeader = ({ columns }) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/

  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  return (
    <TableHeader row>
      {
        columns.map((col, idx) => {
          return (
            <TableHeaderCol row
              key={idx}
              width={col.width}
              flexgrow={col.flexGrow}
              style={col.style}
            >
              {col.name.toUpperCase()}
          </TableHeaderCol>
          )
        })
      }
    </TableHeader>
  );
};

export default EventTableHeader;