/**
 * Modal
 * @brief  Re-usable popup modal
 * @author [Jeremy Aftem]
 */

import React, { } from 'react';
import PopupModalChild from './PopupModalChild';


const PopupModal = (props) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/
  
  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  const render = () => {
    if (!props.open) { return (<div/>); }
    return (
      <PopupModalChild {...props}/>
    );
  }
  return render();
};

export default PopupModal;