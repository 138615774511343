/**
 * Alerts
 * @brief  Alerts component
 * @author [Jeremy Aftem]
 * @notes
 * Using messages received from Pusher, identifies most recent urgent alerts and
 * dispalys them to the user.
 * Note that "pending alert" is not an alert type that exists outside of this
 * component. The parent component Monitor listens for all BedEvents and Alerts
 * identifies what to display.
 */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { LayoutBox } from '../../../../components/layout/styles';
import PopupModal from '../../../../components/library/PopupModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import {
  AlertBox,
  AlertHeader,
  AlertCard,
  AlertBody,
  AlertDesc,
  AlertSound,
  AlertSoundText,
} from './styles';
import AlertsTable from './AlertsTable';

const Alerts = ({ user, alerts, isOpen, onClose }) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/
  const [soundIcon, setSoundIcon] = useState(faVolumeUp);
  const attended = useRef([]);

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/

  const setAttended = (value) => {
    attended.current = value;
  }

  const onClosePopup = useCallback(() => {
    attended.current = [];
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      setSoundIcon(faVolumeUp);
    }
  }, [isOpen]);

  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/
  
  const onClickSound = () => {
    if (soundIcon === faVolumeUp) {
      setSoundIcon(faVolumeMute);
    } else {
      setSoundIcon(faVolumeUp);
    }
  };

/*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  return (
    <div>
      <PopupModal
        open={isOpen}
        onClose={()=>{onClosePopup()}}
      >
        <AlertCard>
          <AlertBox>
            <LayoutBox row>
              <AlertHeader>Pending Alerts</AlertHeader>
              <AlertSound row
                selected={soundIcon === faVolumeUp}
                onClick={()=>onClickSound()}
              >
                <AlertSoundText row>
                  { soundIcon === faVolumeUp ? 'Sound on' : 'Sound off' }
                </AlertSoundText>
                <FontAwesomeIcon
                  icon={soundIcon}
                />
              </AlertSound>
            </LayoutBox> 
            <AlertDesc>
              Please respond to the following urgent alerts:
            </AlertDesc>
            <AlertBody>
              <AlertsTable
                user={user}
                alerts={alerts}
                isOpen={isOpen}
                attended={attended.current}
                setAttended={setAttended}
              />
            </AlertBody>
          </AlertBox>
        </AlertCard>
      </PopupModal>
    </div>
  );
};

export default Alerts;