import styled, {} from 'styled-components';
//import { LayoutBox } from '../../../../../../../components/layout/styles';

export const AttendWrapper = styled.div`
  margin-left: 0px;
  font-size: 14.5px;
  font-weight: 600;
  padding: 8px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 3px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
`;