import {
  isAlert,
  isAttended
} from '../../alerts';

export default class RoomDeviceState {
  constructor(roomDevices) {
    this.data = roomDevices;
  }

  getList() {
    return this.data;
  }

  setList(roomDevices) {
    this.data = roomDevices;
  }
  
  length() {
    return this.data.length;
  }

  /* ------------------------------------------------------------------------ */

  getAlerts() {
    const roomDevices = this.data;
    if (!roomDevices) { return; }
    const alerts = roomDevices.filter((el) => {
      if (!el.bedEvent) { return false; }
      return this._isAlert(el.bedEvent.bedState);
    });
    return alerts;
  }

  getAttended() {
    const roomDevices = this.data;
    if (!roomDevices) { return; }
    const alerts = roomDevices.filter((el) => {
      if (!el.bedEvent) { return false; }
      return this._isAttended(el.bedEvent.bedState);
    });
    return alerts;
  }

  getAlertsAndAttended() {
    const roomDevices = this.data;
    if (!roomDevices) { return; }
    const alerts = roomDevices.filter((el) => {
      if (!el.bedEvent) { return false; }
      return (
        this._isAlert(el.bedEvent.bedState) ||
        this._isAttended(el.bedEvent.bedState)
      );
    });
    return alerts;
  }

  getLatestByReceivedAt(roomDevices) {
    if (!roomDevices) {
      roomDevices = this.data;
    }
    let latest = new Date(roomDevices[0].receivedAt);
    let ret = roomDevices[0];
    roomDevices.forEach((roomDevice) => {
      const currDate = new Date(roomDevice.receivedAt);
      if (currDate > latest) {
        latest = currDate;
        ret = roomDevice;
      }
    });
    return ret; 
  }

  getRoomDeviceById(id) {
    return this.data.filter((roomDevice) => roomDevice.id === id)[0];
  }

  /* ------------------------------------------------------------------------ */
  _isAlert(bedState) {
    return isAlert(bedState);
  }

  _isAttended(bedState) {
    return isAttended(bedState);
  }
};