
export const convertDateToString = (createdAt, now) => {
  const TWENTY_FOUR_HOURS = 60 * 60 * 1000 * 24;
  let time = new Date(createdAt)
  if ((now - time) > TWENTY_FOUR_HOURS) {
    time = time.toLocaleString(
      'en-us',
      {year: 'numeric', month: '2-digit', day: '2-digit'}
    );
  } else {
    time = time.toLocaleTimeString([], {timeStyle: 'short'});
  }
  return time;
};
