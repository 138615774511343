import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { LayoutBox } from '../../../../../../components/layout/styles';

const wiggleAnimation = keyframes`
  0% { transform: rotate(0deg); }
  80% { transform: rotate(0deg); }
  85% { transform: rotate(5deg); }
  95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
`;

export const BedBox = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-grow: 1;
`;

export const SafeBedBox = styled(BedBox)`
  background-color: #FAFAFA;
  &:hover {
  }
`;

export const RedBedBox = styled(BedBox)`
  background-color: #FFE0E9;
  &:hover {
  }
`;

export const YellowBedBox = styled(BedBox)`
  background-color: #FFF0E6;
  &:hover {
  }
`;

export const OffBedBox = styled(BedBox)`
  background-color: '#FFFFFF',
`;

export const BedIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  animation-name: ${wiggleAnimation};
  animation-duration: 2s;
  animation-iteration-count: ${props => props.toWiggle ? 'infinite' : '0'};
`;

export const BedItem = styled.div`
  position: absolute;
  padding: 16px;
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
`;


export const RoomBox = styled(LayoutBox)`
  font-weight: 500;
  color: ${props => props.color};
  text-align: center;
`;

