

export const isAlert = (bedState) => {
  return (
    bedState === 'EMPTY' ||
    bedState === 'SITTING_EDGE'
  );
};

export const isAttended = (bedState) => {
  return (
    bedState === 'ATTENDED'
  );
};

export const isAlertOrAttended = (bedState) => {
  return (
    isAttended(bedState) || isAlert(bedState)
  );
};