/**
 * Live
 * @note the parent container to Monitor must be appropriately sized or the grid
 *       will cause issues.
 * @brief  Live viewing of PA system
 * @author [Jeremy Aftem]
 */

import React, { useEffect, useState } from 'react';
import { LayoutBox } from '../../components/layout/styles';
import Monitor from './Monitor';
import logo from '../../svg/smallLogo.png';
import {
  LiveHeader,
  LiveLogo,
  LiveLogoText,
  LiveMenu,
  LiveMenuItem,
  HelpBox,
} from './styles';
import { useLocation } from 'react-router-dom';
import useVitalacyAPI from '../../hooks/useVitalacyAPI';


const Live = () => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [serverLogin, doServerLogin] = useVitalacyAPI(
    { method: 'POST' },
    { manual: true }
  );

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/
  useEffect(() => {
    if (user) {
      setLoading(false);
      if (!user.groupId) {
        // Send user message they are not part of group
      }
    }
  }, [user]);

  useEffect(() => {
    if (location.state) {
      setUser(location.state.user);
    } else {
      doServerLogin({ url: 'login' });
    }
  }, [location, doServerLogin]);

  useEffect(() => {
    if (serverLogin.isLoaded) {
      setUser(serverLogin.data)
    }
  }, [serverLogin])


  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/

  const render = () => {
    if (isLoading) {
      return ( <div/> )
    } else {
      return (
        <LayoutBox
          column
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0px',
            left: '0px'}}
          >
          <LiveHeader row style={{height: '80px'}}>
            <LiveLogo row>
              <LayoutBox>
                <img src={logo} alt="Logo" />
              </LayoutBox>
              <LiveLogoText>
                Patient Alert
              </LiveLogoText>
            </LiveLogo>
            <LiveMenu row>
              <LiveMenuItem>
                Help   
              </LiveMenuItem>
              <LiveMenuItem>
                Settings
              </LiveMenuItem>
            </LiveMenu>
          </LiveHeader>
          <LayoutBox style={{width: '100%', height: 'calc(100% - 80px)'}}>
            { user.groupId ?
              <Monitor user={user}/>
              :
              <HelpBox row>
                <LayoutBox col>
                  <LayoutBox>
                    Your account "{user.username}" is not assigned to a hospital.
                  </LayoutBox>
                  <LayoutBox>
                    Please contact an administrator.
                  </LayoutBox>
                </LayoutBox>
              </HelpBox>
            }
          </LayoutBox>
        </LayoutBox>
      )
    }
  }

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  return render();
};

export default Live;
