/**
 * Login
 * @brief  Login
 * @author [Jeremy Aftem]
 */

import React, { useState, useEffect, useRef } from 'react';
import { LayoutBox } from '../../components/layout/styles';
import { Formik } from 'formik';
import { cognitoLogin } from '../../app/auth/service';
import { useReactRouter } from '../../hooks';
import useVitalacyAPI from '../../hooks/useVitalacyAPI';

const Login = () => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/
  const {history} = useReactRouter();
  const [loggedIn, setLoggedIn] = useState(false);
  const usernameCheck = useRef(null);
  const user = useRef(null);
  const [serverLogin, doServerLogin] = useVitalacyAPI(
    { method: 'POST' },
    { manual: true }
  );

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/
  useEffect(() => {
    if (loggedIn) {
      console.log('user', user.current);
      history.push({
        pathname: "/live",
        state: { user: user.current },
      });
    }
  }, [loggedIn, history]);

  useEffect(() => {
    if (serverLogin.isLoaded) {
      if (serverLogin.data.username === usernameCheck.current) {
        user.current = serverLogin.data;
        onLoggedIn(true);
      }
    }
  }, [serverLogin])

  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/
  const onLoggedIn = (status) => {
    if (status) {
      setLoggedIn(true);
    }
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await cognitoLogin(
        values.username,
        values.password
      );
      usernameCheck.current = values.username;
      doServerLogin({ url: `login` });
    } catch (e) {
      onLoggedIn(false, e);
    }
  }

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  return (
    <LayoutBox column>
      Login
      <Formik
        initialValues={{ username: '', password: '' }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <input
                type="username"
                name="username"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username}
              />
            </div>
            <div>
              <input
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
            </div>
            <div>
              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>
    </LayoutBox>
  );
};

export default Login;