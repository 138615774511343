import styled from 'styled-components';
import { LayoutBox } from '../../../../../components/layout/styles';

export const ActivityBox = styled(LayoutBox)`
  max-width: ${props => props.maxwidth};
  min-width: ${props => props.minwidth};
`;

export const ActivityLayout = styled(LayoutBox)`
  align-items: center;
`;

export const ActivityHeaderBox = styled(LayoutBox)`
  background-color: #FAFAFA;
  width: ${props => props.width};
`;

export const ActivityHeaderItem = styled(LayoutBox)`
  color: #545454;
  font-size: 12px;
  padding: 10px;
  flex-grow: ${props => props.flexgrow};
  width: ${props => props.width};
`;

export const ActivityListBox = styled(LayoutBox)`
`;