/**
 * ActivityView
 * @brief  ActivityView
 * @author [Jeremy Aftem]
 */

import React, { useState, useEffect } from 'react';
import { } from '../../../../components/layout/styles';
import {
  ActivityLayout,
  ActivityBox,
} from './styles';
import {
  EVENT_TYPE,
  TEXT_TYPE,
} from '../layout/EventTable/EventTableRow/constants';
import EventTable from '../layout/EventTable';
import { convertDateToString } from '../utils/strings';

const ActivityView = ({ bedEvents }) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/
  
  const [table, setTable] = useState({
    columns: [
      {
        name: 'EVENT',
        type: EVENT_TYPE,
        flexGrow: 0,
        width: '90px',
        style: null,
      },
      {
        name: 'DETAILS',
        type: TEXT_TYPE,
        flexGrow: 1,
        width: null,
        style: null,
      },
      {
        name: 'TIME/DATE',
        type: TEXT_TYPE,
        flexGrow: 0,
        width: '15%',
        style: {
          justifyContent: 'right',
          marginRight: '12px'
        }
      },
    ],
    rows: [],
  });

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/

  useEffect(()=> {
    let rows = [];
    bedEvents.forEach((item) => {
      let cols = [
        {text: item.bedState},
        {text: item.message},
        {text: convertDateToString(item.createdAt)},
      ];
      rows.push(cols);
    });
    setTable({
      columns: table.columns,
      rows,
    });
  }, [bedEvents, table.columns]);
  

  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  return (
    <ActivityLayout column>
      <ActivityBox column width={'65%'} minwidth={'500px'} maxwidth={'1000px'}>
        <EventTable data={table} />
      </ActivityBox>
    </ActivityLayout>
  );
};

export default ActivityView;