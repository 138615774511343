import styled, { } from 'styled-components';
import { LayoutBox } from '../../../../../../components/layout/styles';

export const OverviewItem = styled(LayoutBox)`f
`;

export const OverviewBody = styled(LayoutBox)`
  width: 100%;
  padding-bottom: 16px;
  padding-top: 24px;
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

export const OverviewSpacer = styled(LayoutBox)`
  max-width: 200px;
  width: 10%;
`;

export const OverviewItemHeader = styled(LayoutBox)`
  font-size: 14px;
  color: #545454;
`;

export const OverviewItemBody = styled(LayoutBox)`
  margin-top: 20px;
  font-size: 20px;
  color: #1E1E1E;
  line-height: 28px;
`;

export const OverviewAlertWrapper = styled(LayoutBox)`
  align-items: center;
  cursor: pointer;
  background-color: #FFE0E9;
  color: #EB1656;
  font-size: 15px;
  font-weight: 500;
  border-radius: 3px;
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
`;

export const OverviewNormalWrapper = styled(LayoutBox)`
  align-items: center;
  color: #545454;
  font-size: 15px;
  font-weight: 500;
  border-radius: 3px;
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
`;