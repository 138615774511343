/**
 * PopupModalChild
 * @brief  Re-usable popup modal
 * @author [Jeremy Aftem]
 */

import React, { } from 'react';
import { useTransition, animated } from 'react-spring';
import Popup from 'reactjs-popup';

const PopupModalChild = (props) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/

  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/

  const transitions = useTransition(true, p => p, {
    from: {
      opacity: 0,
      transform: 'translate3d(0,-100%,0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0,0%,0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0,50%,0)',
    },
    config: {
      tension: 210,
      friction: 30,
    },
  });

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  const render = () => {
    if (!props.open) { return (<div/>); }
    return (
      <Popup
        open={props.open}
        onClose={() => {
          props.onClose();
        }}
        overlayStyle={{ backgroundColor: 'rgb(0, 15, 59, 0.5)' }}
        contentStyle={{
          border: '1px solid transparent',
          margin: 'auto',
          marginTop: '80px',
          backgroundColor: 'transparent',
          width: '40vw',
          minWidth: '800px',
        }}
      >
        {transitions.map(({ key, props: { ...style } }) => (
          <animated.div key={key} style={style}>
            { props.children }
          </animated.div>
        ))}
      </Popup>
    );
  }
  return render();
};

export default PopupModalChild;