/**
 * useVitalacyAPI
 * @note This file was originally imported from the web_app codebase
 */

import { useCallback, useEffect, useReducer } from 'react';
import * as queryString from 'query-string';
import helpers from './helpers';

/**
 * @hook handles api calls
 * @description uses reducer pattern to propagate state changes
 *                ie dispatch({ type: 'SET_SEARCH', payload: 'filter' });
 *
 * @example shorthand
 *  // we pass a string url as the first argument, which tells the hook we want it to handle execution.
 *  // in the event groupId is null or some other false value, the hook will not execute the api call.
 *  // todo: could be better to reset the hook state if a null arg is passed
 *  const [{ data, isLoading, isLoaded, isError }] = useVitalacyAPI(`endpoint/entity`, { groupId });
 *
 *  // when we pass a third argument to the hook like so, we tell the hook that it can still execute
 *        the api call in the event userId is null or another false value.
 *  @example shorthand w. optional query parameter arguments (refactor)
 *  const [{ data, isLoading, isLoaded, isError }] = useVitalacyAPI(`endpoint/entity`, { groupId }, { userId });
 *
 *  @example manual
 *  // When objects are passed as the first & second args, we tell the hook the HTTP method to use and that we
 *        want to choose when to fetch the data ourselves
 *  // (though don't use it this way if your useEffect has an empty dependency array, just use the shorthand way above)
 *  // You can also declaratively use the getData method (ie in an onClick).
 *
 *  const [data, getData] = useVitalacyAPI({ method: 'GET' }, { manual: true });
 *
 *  useEffect(() => {
 *    getData({ url: `endpoint/entity` });
 *  }, []);
 *
 *  @example manual w. promise.all support
 *  // We leverage promise.all to fetch data from all the endpoints in the argument array
 *
 *  const [data, _, getAllData] = useVitalacyAPI({ method: 'GET' }, { manual: true });
 *
 *  const promises = [{ url: `endpoint/entity`, data: { groupId }}, { url: `otherEndpoint/otherEntity`, data: { userIds } }]
 *
 *  getAllData(promises);
 *
 *  @example clearing hook state
 *  // we clear (reset) the state of the hook, which is sometimes useful
 *
 *  const [data, getData, _, clearData] = useVitalacyAPI({ method: 'GET' }, { manual: true });
 *
 *  useEffect(() => {
 *    if (condition) clearData();
 *  }, [condition]);
 *
 */
function useVitalacyAPI(config, options, optionalOptions) {
  if (typeof config === 'string') {
    config = {
      url: config,
      query: options,
      optionalQuery: optionalOptions,
    };
  }
  const stringifiedConfig = JSON.stringify(config);

  options = { manual: false, ...options };

  const [state, dispatch] = useReducer(helpers.fetchReducer, {
    isLoading: false,
    isLoaded: false,
    isError: false,
    data: [],
  });

  useEffect(() => {
    if (!options.manual) {
      const { query, optionalQuery } = config;
      if (query) {
        const paramValues = Object.values(query);

        if (paramValues.some(param => param)) {
          config.query = { ...query, ...optionalQuery };
          config.url = queryString.stringifyUrl(config);

          delete config.query;
          delete config.optionalQuery;
          helpers.fetchRequester(config, dispatch).catch(() => {});
        }
      } else helpers.fetchRequester(config, dispatch).catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedConfig]);

  const doFetch = useCallback(
    customConfig =>
      helpers.fetchRequester({ ...config, ...customConfig }, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedConfig],
  );

  const doFetchConcurrently = useCallback(
    customConfig =>
      helpers.concurrentFetchRequester(
        customConfig.map(c => ({ ...c, ...config })),
        dispatch,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const doClearState = useCallback(() => helpers.clearRequester(dispatch), []);
  return [state, doFetch, doFetchConcurrently, doClearState];
}

export default useVitalacyAPI;