/**
 * Bed
 * @brief  Bed
 * @author [Jeremy Aftem]
 */

import React, { useEffect, useState } from 'react';
import {
  SafeBedBox,
  RedBedBox,
  YellowBedBox,
  OffBedBox,
  BedItem,
  RoomBox,
  BedIconWrapper
} from './styles';
import { ReactComponent as BedIcon } from '../../../../../svg/BedIcon2.svg';
import { ALERT_STATE, BED_STATE_MAP } from './constants';
import useChannel from '../../../../../hooks/usePusher/useChannel';

const Bed = ({ user, size, bedData, updateLiveBed, onClickBed }) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/
  const [bedState, setBedState] = useState(ALERT_STATE.OFF);
  const [bedEventMessages] = useChannel({
    ch: `vitalacy-pa@${user.groupId}`,
    chEvent: `bed-event`,
  });
  const [alertMessages] = useChannel({
    ch: `vitalacy-pa@${user.groupId}`,
    chEvent: `alert-event`,
  });
  const [latestBedEvent, setLatestBedEvent] = useState(null);

  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/

  /* Handle live bedEvents */
  useEffect(() => {
    if (bedEventMessages.length) {
      const latest = bedEventMessages[bedEventMessages.length - 1];
      if (latest.roomDeviceId === bedData.id) {
        updateLiveBed(null, latest);
        setLatestBedEvent(latest);
      }
    }
  }, [bedEventMessages, bedData.id, updateLiveBed]);

  /* Handle live alerts */
  useEffect(() => {
    if (alertMessages.length) {
      const latest = alertMessages[alertMessages.length - 1];
      if (latest.roomDeviceId === bedData.id) {
        if (latest.alertState === 'ATTENDED') {
          setBedState(ALERT_STATE[latest.alertState]);
        } else {
          setBedState(ALERT_STATE[latest.alertType]);
        }
      }
    }
  }, [alertMessages, bedData.id, updateLiveBed]);

  useEffect(() => {
    if (bedData.alert) {
      setBedState(ALERT_STATE[bedData.alert.alertType]);
    } else if (bedData.bedEvent) {
      setBedState(BED_STATE_MAP[bedData.bedEvent.bedState]);
    } else {
      setBedState(ALERT_STATE['NULL']);
    }
    setLatestBedEvent(bedData.bedEvent);
  }, [bedData]);
  
  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/

  const onClick = () => {
    onClickBed(bedData);
  };

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  const renderBedBox = () => {
    if (bedState.id === ALERT_STATE.OFF.id) {
      return (
        <OffBedBox onClick={()=>{onClick()}}>
          { renderContents(false) }
        </OffBedBox>
      )
    } else if (bedState.id === ALERT_STATE.RISK.id) {
      return (
        <YellowBedBox onClick={()=>{onClick()}}>
          { renderContents(false) }
        </YellowBedBox>
      )
    } else if (bedState.id === ALERT_STATE.ALERT.id) {
      return (
        <RedBedBox onClick={()=>{onClick()}}>
          { renderContents(true) }
        </RedBedBox>
      )
    } else if (
      bedState.id === ALERT_STATE.SAFE.id     ||
      bedState.id === ALERT_STATE.ON.id       ||
      bedState.id === ALERT_STATE.ATTENDED.id ||
      bedState.id === ALERT_STATE.NULL.id) {
      return (
        <SafeBedBox onClick={()=>{onClick()}}>
          { renderContents(false) }
        </SafeBedBox>
      )
    } else {
      return (
        <OffBedBox onClick={()=>{onClick()}}>
          { renderContents(false) }
        </OffBedBox>
      )
    }
  }

  const getRoomName = () => {
    let roomName = bedData ? bedData.room.name : null;
    if (latestBedEvent) {
      if (latestBedEvent.bedState === 'DEVICE_OFF') {
        roomName = roomName + ' (OFF)'
      }
    }
    return roomName;
  }

  const renderContents = (toWiggle) => {
    if (size < 70) { return null; }
    return (
        <BedItem>
          <BedIconWrapper toWiggle={toWiggle}>
            <BedIcon fill={bedState.fill} style={{ height: size/4 + 'px' }}/>
          </BedIconWrapper>
            { size >= 120 ? (
              <RoomBox pt16 color={bedState.fill}>
                { size > 190 ? (
                  <>
                    { getRoomName() }
                  </>
                ) : null}
              </RoomBox>
            ) : null }
        </BedItem>
    )
  }

  return (
    renderBedBox()
  );
};

export default Bed;
