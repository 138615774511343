import styled, { } from 'styled-components';
import { LayoutBox } from '../../../../../components/layout/styles';

export const AlertBox = styled(LayoutBox)`
  padding: 24px;
`;

export const AlertHeader = styled(LayoutBox)`
  font-size: 26px;
  font-weight: 500;
  color: #545454;
  flex-grow: 1;
`;

export const AlertCard = styled.div`
  width: 40vw;
  max-height: 70vh;
  min-width: 1000px;
  background: #fff;
  border-top: 8px solid #EB1656;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;
`;

export const AlertBody = styled(LayoutBox)`
  padding-top: 24px;
`;

export const AlertLayout = styled(LayoutBox)`
  align-items: center;
`;

export const AlertHeaderBox = styled(LayoutBox)`
  background-color: #FAFAFA;
  width: ${props => props.width};
`;

export const AlertHeaderItem = styled(LayoutBox)`
  color: #545454;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  flex-grow: ${props => props.flexGrow};
  width: ${props => props.width};
`;

export const AlertListBox = styled(LayoutBox)`
`;

export const AlertDesc = styled(LayoutBox)`
  margin-top: 24px;
`;

export const AlertSound = styled(LayoutBox)`
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  background-color: ${props => (props.selected ? '#FFE0E9' : '#FAFAFA')};
  color: ${props => (props.selected ? '#EB1656' : '#858585')};
  padding: 10px;
  border-radius: 3px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
`;


export const AlertSoundText = styled(LayoutBox)`
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding-right: 14px;
  font-weight: 500;
`;