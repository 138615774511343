import './App.css';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Live from '../domain/Live';
import Login from '../domain/Login';
import React, { useEffect } from 'react';
import { checkLogin } from './auth/service';
import { useReactRouter } from '../hooks';
import { useLocation } from 'react-router-dom';
import Pusher from 'pusher-js';
import { PusherProvider } from '../hooks/usePusher';

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
})

if (process.env.NODE_ENV === 'LOCAL') { pusher.logToConsole = true; }

const App = () => {

  const { history } = useReactRouter();
  const location = useLocation();

  useEffect(() => {
    async function fetchLogin() {
      //cognitoLogout(); // for testing
      try {
        await checkLogin();
      } catch (e) {
        /* Creds not found or creds have expired */
        if (location.pathname !== '/auth') {
          history.push("/auth");
        }
      }
    }
    fetchLogin();
  }, [location, history]); 

  return (
    <div>
      <PusherProvider pusher={pusher}>
        <Switch>
          <Route path="/live">
            <Live />
          </Route>
          <Route path="/auth">
            <Login />
          </Route>
          <Redirect to="/auth" />
        </Switch>
      </PusherProvider>
    </div>
  );
};

export default App;