import axios from 'axios';
//const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
/*import AWS from 'aws-sdk';*/

let cognitoUser = null;

export const getToken = () => {
  if (cognitoUser !== null) {
    return cognitoUser.signInUserSession.idToken.jwtToken;
  }
  return null;
};

const setAuthHeader = token => {
  axios.defaults.headers.common.Authorization = token;
};

export const checkLogin = () => {
  return new Promise((resolve, reject) => {
    const userPool = getUserPool()
    cognitoUser = userPool.getCurrentUser();
    if (cognitoUser !== null) {
      // getSessions refreshes tokens behind the scenes if necessary. 
      cognitoUser.getSession(function(err, session) {
        if (err) {
          // Ask user to re-authenticate at /auth
          console.log("Error getting cognito user session");
          reject(err);
        } else {
          setAuthHeader(session.idToken.jwtToken);
          resolve(cognitoUser);
        }
      });
    } else {
      reject('No browser-saved user.');
    }
  })
}

export const cognitoLogin = (username, password) => {
  return new Promise((resolve, reject) => {
    const authenticationData = {
      Username : username,
      Password : password,
    };
    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    const userPool = getUserPool();
    const userData = {
      Username : username,
      Pool : userPool
    };
    cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          const idToken = result.idToken.jwtToken;
          console.log("TOKEN:", idToken);
          setAuthHeader(idToken);
          resolve(cognitoUser);
        },
        onFailure: function(err) {
          cognitoUser = null;
          reject(err);
        },
    });
  });
};

const getUserPool = () => {
  const poolData = {
    UserPoolId : "us-east-2_1beGB0CB1", 
    ClientId   : "79liv2cjiupjncocqgc3ts7nk"
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
  return userPool;
}

const getUserFromStorage = () => {
  if (cognitoUser === null) {
    const userPool = getUserPool();
    cognitoUser = userPool.getCurrentUser();
  }
}

export const logout = async () => {
  await cognitoLogout();
  return;
}

export const cognitoLogout = async () => {
  getUserFromStorage();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
};

/* Probably don't need to set these creds (for Identity Provider service) */
/*
const setAwsConfigCreds = (jwtToken) => {
  AWS.config.region = 'us-east-2';
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-2:1d7dca4d-b74a-4713-afdc-0221000f3b92',
    Logins: {
      'cognito-idp.us-east-2.amazonaws.com/us-east-2_1beGB0CB1': jwtToken
    },
  });
  AWS.config.credentials.refresh(error => {
    if (error) {
      console.error('refresh error', error);
    } else {
      console.log('Successfully logged!');
      console.log('Aws.config.credentials=', AWS.config.credentials);
      console.log('CognitoUser=', cognitoUser);
    }
  });
*/