export const ALERT_TYPE = 'ALERT_TYPE';
export const EVENT_TYPE = 'EVENT_TYPE';
export const TEXT_TYPE = 'TEXT_TYPE';
export const JSX_TYPE = 'JSX_TYPE';

export const EVENT_WRAPPER_CSS = {
  'alert' : {
     eventCSS: {
       color: '#EB1656',
       backgroundColor: '#FFE0E9',
     },
  },
  'attended' : {
    eventCSS: {
      color: '#006699',
      backgroundColor: '#E0F2F9',
    }
  },
  'risk'  : {
    eventCSS: {
      color: '#EB8439',
      backgroundColor: '#FFF0E6',
    },
  },
  'safe'  : {
    eventCSS: {
      color: '#00998C',
      backgroundColor: '#CEF2EF',
    },
  },
  'on'    : {
    eventCSS: {
      color: '#545454',
      backgroundColor: '#FAFAFA',
    },
  },
  'off'   : {
    eventCSS: {
      color: '#545454',
      backgroundColor: '#FAFAFA'
    },
  },
  'unknown'   : {
    eventCSS: {
      color: '#545454',
      backgroundColor: '#FAFAFA'
    },
  },
  'none' : {
    eventCSS: {
      color: '#545454',
      backgroundColor: '#FAFAFA'
    },
  }
};

export const BED_STATE = {
  RISK  : {
    id: 'RISK',
    fill: '#EB8439'
  },
  SAFE  : {
    id: 'SAFE',
    fill: '#545454'
  },
  ATTENDED  : {
    id: 'ATTENDED',
    fill: '#545454'
  },
  ALERT  : {
    id: 'ALERT',
    fill: '#EB1656'
  },
  OFF  : {
    id: 'OFF',
    fill: '#CCCCCC'
  },
  ON   : {
    id: 'ON',
    fill: '#545454',
  },
  NULL : {
    id: 'UNKNOWN',
    fill: '#CCCCCC'
  }
}

export const BED_STATE_MAP = {
  'LYING' : BED_STATE.SAFE,
  'SITTING': BED_STATE.SAFE,
  'SITTING_EDGE': BED_STATE.RISK,
  'EMPTY': BED_STATE.ALERT,
  'NULL': BED_STATE.NULL,
  'ATTENDED': BED_STATE.ATTENDED,
  'DEVICE_OFF': BED_STATE.OFF,
  'DEVICE_ON': BED_STATE.ON,
};
