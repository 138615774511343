/**
 * Monitor
 * @brief  Live monitor
 * @author [Jeremy Aftem]
 */

import React, { useState, useEffect, useCallback } from 'react';
import { LayoutBox } from '../../../components/layout/styles';
import { NavButton, MonitorBodyBox, MonitorBox } from './styles';
import {
  ACTIVITY_VIEW,
  BED_VIEW,
} from './constants';
import ActivityView from './Activity';
import BedsView from './Beds';
import useVitalacyAPI from '../../../hooks/useVitalacyAPI';
import useChannel from '../../../hooks/usePusher/useChannel';
import Overview from './Beds/Overview';
import MonitorPopups from './MonitorPopups';
import RoomDeviceState from './utils/alerts/RoomDeviceState';

const MENU = [BED_VIEW, ACTIVITY_VIEW];

const Monitor = ({ user }) => {
  /*--------------------------------------------------------------------------*/
  /* State
  /*--------------------------------------------------------------------------*/
  const [view, setView] = useState(BED_VIEW.id);

  // Room devices
  const [roomDevicesCall, doRoomDevicesCall] = useVitalacyAPI(
    { method: 'GET' },
    { manual: true }
  );
  const [roomDevices, setRoomDevices] = useState([]);

  // Bed events
  const [bedEventsCall, doBedEventsCall] = useVitalacyAPI(
    { method: 'GET' },
    { manual: true }
  );
  const [messages, clearMessages] = useChannel({
    ch: `vitalacy-pa@${user.groupId}`,
    chEvent: `bed-event`,
  });
  const [bedEvents, setBedEvents] = useState([]);

  // Loading
  const [isLoading, setLoading] = useState(true);

  // Alerts
  const [roomDeviceState, setRoomDeviceState] = useState(null);
  const [isAlertsOpen, setAlertsOpen] = useState(false);

  // Selected bed
  const [clickedBed, setClickedBed] = useState(null);
  const [isBedPopupOpen, setBedPopupOpen] = useState(false);

  // Alerts
  // eslint-disable-next-line no-unused-vars
  const [alertsCall, doAlertsCall] = useVitalacyAPI(
    { method: 'GET' },
    { manual: true },
  );
  const [alertChannel, clearAlertChannel] = useChannel({
    ch: `vitalacy-pa@${user.groupId}`,
    chEvent: 'alert-event'
  });
  const [alerts, setAlerts] = useState([]);


  /*--------------------------------------------------------------------------*/
  /* Hooks
  /*--------------------------------------------------------------------------*/

  useEffect(() => {
    if (alertChannel.length) {
      let latest = alertChannel[alertChannel.length - 1];
      latest.receivedAt = new Date().toString();
      let toUpdate = [...alerts];
      let replaced = false;
      toUpdate.forEach((alert, idx) => {
        if (
          alert.id === latest.id ||
          alert.roomDeviceId === latest.roomDeviceId
        ) {
          toUpdate[idx] = latest;
          replaced = true;
        }
      });
      if (!replaced) {
        toUpdate.unshift(latest); // pre-pend to front
      }
      setAlerts(toUpdate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertChannel]);

  useEffect(() => {
    if (alertsCall.isLoaded) {
      const now = new Date().toString();
      const toUpdate = alertsCall.data.map((_alert) => {
        _alert.receivedAt = now;
        return _alert;
      });
      setAlerts([...toUpdate]);
    }
  }, [alertsCall]);

  const refreshData = useCallback((_user) => {
    setLoading(true);
    clearMessages();
    clearAlertChannel();
    doRoomDevicesCall({
      url: `/roomdevice/group/${_user.groupId}`
    });
    doBedEventsCall({
      url: `/bedevent/group/${_user.groupId}`
    });
    doAlertsCall({
      url: `/bedalert/group/${_user.groupId}`
    });
  }, [
    clearMessages,
    clearAlertChannel,
    doRoomDevicesCall,
    doBedEventsCall,
    doAlertsCall
  ]);

  useEffect(() => {
    refreshData(user);
  }, [user, refreshData]);

  useEffect(() => {
    if (roomDevicesCall.isLoaded && alertsCall.isLoaded) {
      const now = new Date().toString();
      let toUpdate = roomDevicesCall.data.map((roomDevice) => {
        roomDevice.receivedAt = now;
        alertsCall.data.forEach((alert) => {
          if (alert.roomDeviceId === roomDevice.id) {
            roomDevice.alert = alert;
          }
        })
        return roomDevice;
      });
      setRoomDevices(toUpdate);
    }
    if (bedEventsCall.isLoaded) {
      setBedEvents(bedEventsCall.data);
    }
    if (
      roomDevicesCall.isLoaded &&
      bedEventsCall.isLoaded &&
      alertsCall.isLoaded
    ) {
      setLoading(false);
    }
  }, [roomDevicesCall, bedEventsCall, alertsCall]);

  useEffect(() => {
    setBedEvents(messages.reverse().concat(bedEventsCall.data))
  }, [bedEventsCall, messages]);

  const updatePending = useCallback((liveUpdates, roomDevices) => {
    if (liveUpdates.length) {
      const update = liveUpdates[0];
      let toUpdate = roomDevices.map((p) => {
        if (p.id === update.roomDeviceId) {
          let ret = JSON.parse(JSON.stringify(p));
          ret.bedEvent = update;
          ret.receivedAt = new Date().toString();
          return ret;
        } else {
          return p;
        }
      });
      setRoomDeviceState(new RoomDeviceState([...toUpdate]));
    } else {
      setRoomDeviceState(new RoomDeviceState([...roomDevices]));
    }
  }, []);

  useEffect(() => {
    updatePending(messages, roomDevices);
  }, [messages, roomDevices, updatePending]);

  /*--------------------------------------------------------------------------*/
  /* Helper functions
  /*--------------------------------------------------------------------------*/
  const tabOnClick = (viewId) => {
    setView(viewId);
    refreshData(user);
  };

  const renderTopMenu = () => {
    return MENU.map((menuItem) => {
      return (
        <NavButton
          selected={view === menuItem.id}
          onClick={()=>{tabOnClick(menuItem.id)}}
          key={menuItem.id}
        >
          { menuItem.text }
        </NavButton>
      )
    })
  };

  const onClickAlerts = () => {
    setAlertsOpen(true);
  };

  const onClickBed = (bedData) => {
    setClickedBed({...bedData});
    setBedPopupOpen(true);
  }

  const onClosePopups = () => {
    setAlertsOpen(false);
    setBedPopupOpen(false);
  }

  /*--------------------------------------------------------------------------*/
  /* Return
  /*--------------------------------------------------------------------------*/
  return (
    <MonitorBox column>
      <LayoutBox row spaceBtx>
        <LayoutBox row flex spaceBtx width={'155px'}>
          { renderTopMenu() }
        </LayoutBox>
      </LayoutBox>
      { !isLoading && (
          <MonitorBodyBox column>
            {
              view === BED_VIEW.id ?
              <>
                <Overview
                  alerts={alerts}
                  onClickAlerts={onClickAlerts}
                  roomDeviceState={roomDeviceState}
                /> 
                <BedsView
                  user={user}
                  roomDevicesData={roomDevices}
                  updateRoomDevices={setRoomDevices}
                  onClickBed={onClickBed}
                />
             </> 
              : null
            }
            { view === ACTIVITY_VIEW.id ?
                <ActivityView
                  bedEvents={bedEvents}
                />
              : null }
          </MonitorBodyBox>
      )}
      <MonitorPopups
        user={user}
        alerts={alerts}
        onAlertsClicked={isAlertsOpen}
        onBedPopupClicked={isBedPopupOpen}
        bedPopupData={clickedBed}
        onClosePopups={onClosePopups}
      />
    </MonitorBox>
  );
};

export default Monitor;