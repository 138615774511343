

export const ALERT_STATE = {
  RISK  : {
    id: 'RISK',
    fill: '#EB8439'
  },
  SAFE  : {
    id: 'SAFE',
    fill: '#545454'
  },
  ATTENDED  : {
    id: 'ATTENDED',
    fill: '#545454'
  },
  ALERT  : {
    id: 'ALERT',
    fill: '#EB1656'
  },
  OFF  : {
    id: 'OFF',
    fill: '#CCCCCC'
  },
  ON   : {
    id: 'ON',
    fill: '#545454',
  },
  NULL : {
    id: 'UNKNOWN',
    fill: '#CCCCCC'
  }
}

export const BED_STATE_MAP = {
  'LYING' : ALERT_STATE.SAFE,
  'SITTING': ALERT_STATE.SAFE,
  'SITTING_EDGE': ALERT_STATE.RISK,
  'EMPTY': ALERT_STATE.ALERT,
  'NULL': ALERT_STATE.NULL,
  'ATTENDED': ALERT_STATE.ATTENDED,
  'DEVICE_OFF': ALERT_STATE.OFF,
  'DEVICE_ON': ALERT_STATE.ON,
};