/**
 * useVitalacyAPI helpers
 * @note This file was originally imported from the web_app codebase.
 */
import axios from 'axios';

const fetchReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST_INIT':
      return { ...state, isLoading: true, isLoaded: false, isError: false };
    case 'REQUEST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        data: action.payload,
      };
    case 'REQUEST_FAILURE':
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: action.payload,
      };
    case 'REQUEST_CLEAR':
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: false,
        data: [],
      };
    default:
      throw new Error();
  }
};

function clearRequester(dispatch) {
  dispatch({ type: 'REQUEST_CLEAR' });
}

async function fetchRequester(config, dispatch) {
  try {
    dispatch({ type: 'REQUEST_INIT' });
    const { data } = await axios(config);
    dispatch({ type: 'REQUEST_SUCCESS', payload: data });
  } catch (e) {
    /* not all error types are mapped out, and parts of app rely on isError being set to true to trigger a notification */
    let message = true;
    if (e.response) {
      const { data } = e.response;
      message = data.message || data.error;
      if (data.status !== 200) {
        message = data.errors && data.errors.length && data.errors[0].message;
      }
    }
    dispatch({ type: 'REQUEST_FAILURE', payload: message });
  }
}

async function concurrentFetchRequester(configArray, dispatch) {
  try {
    dispatch({ type: 'REQUEST_INIT' });
    const promises = configArray.map(config => axios(config));
    const responses = await Promise.all(promises);

    const data = responses.map(({ data }) => data);

    dispatch({ type: 'REQUEST_SUCCESS', payload: data });
  } catch (e) {
    dispatch({ type: 'REQUEST_FAILURE' });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchReducer,
  fetchRequester,
  concurrentFetchRequester,
  clearRequester,
};
