import styled, { } from 'styled-components';
import { LayoutBox } from '../../../../components/layout/styles';

export const NavButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  color: ${props => props.primary && '#fff'};
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  color: #545454;
  background-color: ${props => props.selected ? '#F0F0F0' : '#FFFFFF'};
  font-weight: ${props => props.selected? 600 : 500 };
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
`;

export const MonitorBox = styled(LayoutBox)`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px;
  padding-top: 0px;
`;

export const MonitorBodyBox = styled(LayoutBox)`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;